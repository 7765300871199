import $ from "jquery";

$(document).on("lity:open", function (event, instance) {
	const modalInstance = instance.element();
	const modalHeader = modalInstance[0].getElementsByClassName("modal-header");

	setTimeout(() => {
		const modalContainer =
			modalInstance[0].getElementsByClassName("lity-container");

		$(modalHeader[0])
			.clone()
			.addClass("modal-header--fixed")
			.appendTo(modalContainer[0]);
	}, 300);
});
