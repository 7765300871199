import $ from "jquery";
import { getCookie, setCookie } from "/lib/cookie";

$(document).ready(function () {
  if (!getCookie("banner-seen")) {
    const $notice = $(".js-banner-notice");
    $notice.slideDown();
    $(".js-close-banner").click(function () {
      $notice.slideUp();
      setCookie("banner-seen", "true", 1);
    });
  }
});
