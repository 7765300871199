import $ from 'jquery'
$(document).ready( initIconGridSlider )

function initIconGridSlider(){
    if($('.js-infinite-slider').length){
        $('.js-infinite-slider').each(function(){
            $(this).find('.icon-grid__icons').slick({
                speed: 9000,
                autoplay: true,
                autoplaySpeed: 0,
                cssEase: 'linear',
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                arrows: false,
                dots: false
            });
        });
    }
}
