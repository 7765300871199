import $ from "jquery";
import "slick-carousel";

const goToFirstButton = $(".js-timeline-first");
const goToPreviousButton = $(".js-timeline-previous");
const goToNextButton = $(".js-timeline-next");
const goToLastButton = $(".js-timeline-last");
let onLastSlide = false;
let onFirstSlide = true;

function initTimelineSlider() {
	const imageSlider = $(".js-timeline-image-slider");

	if (imageSlider.length) {
		imageSlider.slick({
			arrows: false,
			dots: false,
			asNavFor: ".js-timeline-year-slider",
		});
	}
}

function initYearSlider() {
	const yearSlider = $(".js-timeline-year-slider");

	if (yearSlider.length) {
		yearSlider.slick({
			draggable: false,
			arrows: false,
			dots: false,
			slidesToShow: 5,
			slidesToScroll: 1,
			infinite: false,
			asNavFor: ".js-timeline-image-slider",
			centerMode: true,
			focusOnSelect: true,
		});

		// Check if the slider is on the last slide
		yearSlider.on("afterChange", function (event, slick, currentSlide) {
			if (currentSlide === slick.slideCount - 1) {
				onLastSlide = true;
			} else if (currentSlide < slick.slideCount - 1) {
				onLastSlide = false;
			}

			if (currentSlide > 0) {
				onFirstSlide = false;
			} else if (currentSlide === 0) {
				onFirstSlide = true;
			}

			if (onFirstSlide) {
				goToFirstButton.attr("disabled", true);
				goToPreviousButton.attr("disabled", true);
			}

			if (!onFirstSlide) {
				goToFirstButton.attr("disabled", false);
				goToPreviousButton.attr("disabled", false);
			}

			if (onLastSlide) {
				goToLastButton.attr("disabled", true);
				goToNextButton.attr("disabled", true);
			}

			if (!onLastSlide) {
				goToLastButton.attr("disabled", false);
				goToNextButton.attr("disabled", false);
			}
		});
	}
}

function initDescriptionSlider() {
	const yearSlider = $(".js-timeline-description-slider");

	if (yearSlider.length) {
		yearSlider.slick({
			draggable: false,
			arrows: false,
			dots: false,
			fade: true,
		});
	}
}

goToFirstButton.on("click", () => {
	$(".js-timeline-year-slider, .js-timeline-description-slider").slick(
		"slickGoTo",
		0
	);
});

goToPreviousButton.on("click", () => {
	if (onFirstSlide) return;
	$(".js-timeline-year-slider, .js-timeline-description-slider").slick(
		"slickPrev"
	);
});

goToNextButton.on("click", () => {
	if (onLastSlide) return;
	$(".js-timeline-year-slider, .js-timeline-description-slider").slick(
		"slickNext"
	);
});

goToLastButton.on("click", () => {
	$(".js-timeline-year-slider, .js-timeline-description-slider").slick(
		"slickGoTo",
		100
	);
});

$(function () {
	initTimelineSlider();
	initYearSlider();
	initDescriptionSlider();
});

if (window.acf) {
	window.acf.addAction(
		"render_block_preview/type=timeline",
		initTimelineSlider
	);
}
