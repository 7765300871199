import $ from 'jquery'
import 'slick-carousel'

$(document).ready(initTopPostsSlider)
if (window.acf) {
  window.acf.addAction('render_block_preview/type=slider', initTopPostsSlider)
}

function initTopPostsSlider() {
  const slider = $('.js-top-posts-slider')

  if (slider.length) {
    slider.slick({
      arrows: true,
      dots: true,
      autoplay: false,
      infinite: false,
      nextArrow:
        '<button class="slick-next slick-arrow" aria-label="Next" type="button"><svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.62717 0.666992L4.25 2.99604L14.3517 12.8923L4.25 22.7895L6.62717 25.1175L19.1051 12.8923L6.62717 0.666992Z" fill="white"/><path d="M3.06822 4.21069L0.626953 6.52627L3.67724 9.41953L6.11851 11.7351L7.33863 12.8924L0.626953 19.2585L3.06822 21.5741L12.2212 12.8924L3.06822 4.21069Z" fill="white"/></svg></button>',
      prevArrow:
        '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><svg width="19" height="26" viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4783 25.1172L14.8555 22.7881L4.75374 12.8919L14.8555 2.9947L12.4783 0.666639L0.000396844 12.8919L12.4783 25.1172Z" fill="white"/><path d="M16.0373 21.5735L18.4785 19.2579L15.4282 16.3647L12.987 14.0491L11.7668 12.8918L18.4785 6.52563L16.0373 4.21005L6.88431 12.8918L16.0373 21.5735Z" fill="white"/></svg></button>',
    })
  }
}
